import {
  BundleArgs,
  CsrBundle,
  csrBundle,
  defineFeatureServices,
} from '@oneaudi/feature-services';
import {
  FeatureServiceProviderDefinition,
  FeatureServices,
  Logger,
  SharedFeatureService,
} from '@feature-hub/core';
import {
  getAuthOptions,
  getConfiguratorLinkOptions,
  getCookieServiceOption,
  getCurrentCarlineOptions,
  getDisclaimerManagerOptions,
  getEnvConfigOptions,
  getGfaServiceConfig,
  getHistoryServiceConfig,
  getI18nOptions,
  getLocaleOptions,
  getMarketContextServiceOptions,
  getNavigationOptions,
  getNumberFormatterOptions,
  getOneGraphOptions,
  getRenderModeOptions,
  getTrackingOptions,
  getVueFormatterOptions,
  prepareWindow,
} from './service-configuration';

import { ContentStore } from '@oneaudi/content-service';
import { createLogger } from '../logger';

export type FeatureServiceConfigOverride = Partial<BundleArgs<CsrBundle>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    SETUPS: {
      get(name: string): string;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    featureServiceDefinitions: Array<FeatureServiceProviderDefinition<any>>;
    featureServiceConfigs?: FeatureServiceConfigOverride;
    digitalData: Record<string, unknown>;
    audiIntegrator?: {
      printFeatureHubInfo?(): void;
    };
  }
}

export const createFeatureServiceDefinitions = (
  logger: Logger,
): {
  contentStore: ContentStore;
  featureServiceDefinitions: FeatureServiceProviderDefinition<
    SharedFeatureService,
    FeatureServices
  >[];
} => {
  prepareWindow();

  const contentStore =
    window.featureServiceConfigs?.['audi-content-service']?.[0] ||
    new ContentStore();

  const serviceArgs: BundleArgs<CsrBundle> = {
    'audi:envConfigService': [getEnvConfigOptions(document.head)],
    'audi-audicode-service': [],
    'audi-configurator-data-service': [],
    'audi-configurator-link-service': [
      getConfiguratorLinkOptions(document.head),
    ],
    'audi-content-service': [contentStore],
    'audi-current-carline-service': [getCurrentCarlineOptions(document.head)],
    'audi-dynamic-footnote-service': [],
    'audi-footnote-reference-service': [],
    'audi-market-context-service': [
      getMarketContextServiceOptions(document.head),
    ],
    'audi-number-formatter-service': [getNumberFormatterOptions(document.head)],
    'audi-render-mode-service': [getRenderModeOptions()],
    'audi-tracking-service': [getTrackingOptions()],
    'cookie-service': [getCookieServiceOption()],
    'dbad:audi-i18n-service': [getI18nOptions(document.head)],
    'dbad:audi-vue-formatter-service': [getVueFormatterOptions(document.head)],
    'disclaimer-manager': [getDisclaimerManagerOptions()],
    'gfa:service-config-provider': [getGfaServiceConfig()],
    'locale-service': [getLocaleOptions(document.head)],
    'navigation-service': [getNavigationOptions()],
    'onegraph-query-service': [],
    'onegraph-service': [getOneGraphOptions(document.head)],
    's2:history': [getHistoryServiceConfig(document.head, document.body)],
    's2:logger': [createLogger],
    'user-vehicle-feature-service': [],
    'vw:authService': [getAuthOptions(document.head)],
    ...window.featureServiceConfigs,
  };

  const serviceDefinitions = defineFeatureServices(
    csrBundle,
    serviceArgs,
    logger,
  );

  return {
    contentStore,
    featureServiceDefinitions: [
      ...serviceDefinitions,
      ...window.featureServiceDefinitions,
    ],
  };
};
