import * as FeatureHubReact from '@feature-hub/react';
import * as ModuleLoaderAmd from '@feature-hub/module-loader-amd';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as UnifiedWebCommon from '@oneaudi/unified-web-common';
import * as audiUiReactV2 from '@audi/audi-ui-react-v2';
import * as oneGraphClient from '@oneaudi/onegraph-client';
import * as oneGraphQueryService from '@oneaudi/onegraph-query-service';
import * as oneGraphService from '@oneaudi/onegraph-service';
import * as styled from 'styled-components';
import axios from 'axios';

// Add support for default imports of axios in feature apps, despite axios being
// a CJS module. For that to work in a bundled Feature App that has axios
// specified as an external, we need to define the `__esModule` property that
// webpack uses to decide whether to use the `default` property, or the module
// itself. The module itself is transformed to a Module object by SystemJS
// though, whereas the `default` property is the default function (with
// additional properties). See https://github.com/systemjs/systemjs/issues/2090
Object.defineProperty(axios, '__esModule', { value: true });

const definedExternals: ModuleLoaderAmd.Externals = {
  '@audi/audi-ui-react': audiUiReactV2,
  '@audi/audi-ui-react-v2': audiUiReactV2,
  '@feature-hub/module-loader-amd': ModuleLoaderAmd,
  '@feature-hub/react': FeatureHubReact,
  '@oneaudi/onegraph-client': oneGraphClient,
  '@oneaudi/onegraph-query-service': oneGraphQueryService,
  '@oneaudi/onegraph-service': oneGraphService,
  '@oneaudi/unified-web-common': UnifiedWebCommon,
  '@volkswagen-onehub/onegraph-client': oneGraphClient,
  axios,
  react: React,
  'react-dom': ReactDom,
  'styled-components': styled,
};

const providedExternals = {
  /**
   * for absolute fail safety we're still gonna keep pretending that we have audi-ui-react v1, but we're gonna deliver v2 instead ;)
   * Can be removed when v2 is also removed
   */
  '@audi/audi-ui-react': '1.6.0',
  '@audi/audi-ui-react-v2': process.env.AUDI_UI_REACT_V2_VERSION as string,
  '@feature-hub/module-loader-amd': process.env.FEATURE_HUB_VERSION as string,
  '@feature-hub/react': process.env.FEATURE_HUB_VERSION as string,
  '@oneaudi/onegraph-client': process.env.ONEGRAPH_CLIENT_VERSION as string,
  '@oneaudi/onegraph-query-service':
    process.env.SERVICE_ONEGRAPH_QUERY_SERVICE_VERSION,
  '@oneaudi/onegraph-service': process.env.SERVICE_ONEGRAPH_SERVICE_VERSION,
  '@oneaudi/unified-web-common': process.env
    .UNIFIED_WEB_COMMON_VERSION as string,
  '@volkswagen-onehub/onegraph-client': process.env
    .ONEGRAPH_CLIENT_VERSION as string,
  axios: process.env.AXIOS_VERSION as string,
  react: process.env.REACT_VERSION as string,
  'react-dom': process.env.REACT_DOM_VERSION as string,
  'styled-components': process.env.STYLED_COMPONENTS_VERSION as string,
};

export { definedExternals, providedExternals };
